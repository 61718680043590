<template>
	<div>
		<div class="row">
			<div class="col pr-0">
				<e-select
			        track-by="lieu_id"
					label="lieu_label"
			        v-model="lieu"
			        :options="residences"
			        :placeholder="$t('mouvement.rechercher_lieu')"
			        :loading="isLoadingLieu"
			        :sortable="false"
			    >
			        <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
			        <template slot="option" slot-scope="{ option }">{{ option.lieu_label }}</template>
		    	</e-select>
			</div>
			<div v-if="intralocation_label !== ''" class="col p-0">
				<b-input type="text" class="form-control" disabled v-model="intralocation_label" />
			</div>
			<div class="col-3">
                <e-select
                    v-model="selected_contact"
                    id="contact_id"
                    track-by="contact_id"
                    label="contact_label"
                    :placeholder="$t('acte.selectionnez_contact')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="contacts"
                    :searchable="true"
                    :allow-empty="true"
                    :loading="isLoadingContact"
                    :show-labels="false"
                    :group-select="false"
                    :multiple="true"
                />
            </div>
            <div class="col-1 p-0">
            	<e-datepicker v-model="date"></e-datepicker>
            </div>
	        <div class="col-2 ml-auto">
	            <button @click="loadMap" class="btn btn-block btn-primary">{{ $t('global.rechercher') }}</button>
	        </div>
		</div>
		<div v-if="showMap">
			<MapLocalisation 
				ref="map_localisation"
				:residence="lieu"
				:intralocation_label.sync="intralocation_label"
				:contacts="selected_contact"
				:date="date"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Lieux from '@/mixins/Lieux'
	import Contact from "@/mixins/Contact.js"

	export default {
		name: 'SelectResidence',
		mixins: [Lieux, Contact],
		data () {
			return {
				residences: [],
				lieu: null,
				isLoadingLieu: false,
				showMap: false,
				intralocation_label: '',
				contacts: [],
				selected_contact: [],
				isLoadingContact: false,
				date: new Date()
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.isLoadingLieu = true
	            const all_lieux = await this.getLieux(false)
	            this.residences = all_lieux.filter(lieu => lieu.lieu_type == 1 && lieu.lieu_archive == 0)
				// On select le premier lieu actif avec registre d'élevage par défaut
				if(this.residences.filter(lieu => lieu.lieu_type == 1 && lieu.lieu_registry_enable).length >= 1) {
					this.lieu = this.residences[0]
					this.loadMap()
				}
	            this.isLoadingLieu = false

	            this.isLoadingContact = true
	            this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false
			},

			loadMap() {
				this.showMap = false
				if(!this.lieu) return false
				this.$nextTick(function () {
					this.showMap = true
            	})
			}
		},

		components: {
			MapLocalisation: () => import('@/components/Localisation/MapLocalisation'),
		}
	}

</script>